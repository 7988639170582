import React from 'react';
import './EndCard.css';

function EndCard(props) {
	return (
		<div className={`end-card-${props.isVisible ? "active" : "inactive"}`}>
			<div className="card-wrapper">
				<h2>{props.winner} won!</h2>
				<div className="button-wrapper">
					<div className="button" onClick={props.onClick}>Restart Game</div>
				</div>
			</div>
		</div >
	)
}

export default EndCard;