import React from 'react';
import Cell from './Cell'

function TTTBoard(props) {
	const allCells = [];
	for (let i = 0; i < 9; i++) {
		const newCell = <Cell
			mark={props.boardState[i]}
			onClick={() => props.onClick(i)}
			key={`Cell ${i}`}/>
		allCells.push(newCell);
	}
	const styleClass = props.isActive ? "active-board" : "disabled-board";

	return (
		<div className={`ttt-board ${styleClass}`}>
			{(props.winner === "X" || props.winner === "O") &&
				<div className={`winning-sign-${props.winner}`}></div>
			}
			{allCells}
		</div>
	)
}

export default TTTBoard
