import React, { Component } from 'react'
import EndCard from './EndCard';
import Header from './Header'
import MegaTTTBoard from './MegaTTTBoard'
import PlayerMenu from './PlayerMenu'

class TwoPlayerGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      winsPlayerX: 0,
      winsPlayerO: 0,
      currentPlayer: "X",
      endCardVisible: false,
      shouldResetBoard: false
    }
    // setInterval(this.changePlayers, 5000);
  }

  onWin = (winner) => {
    if (winner === "X") this.setState({ winsPlayerX: this.state.winsPlayerX + 1 })
    if (winner === "O") this.setState({ winsPlayerO: this.state.winsPlayerO + 1 })
    // undo the player change
    this.changePlayers();
    this.setState({
      endCardVisible: true
    });
  }

  changePlayers = () => {
    this.setState({
      currentPlayer: this.state.currentPlayer === "X" ? "O" : "X",
    })
    if (this.state.currentPlayer === "X") {
      // scrollt das Wallpaper nach Links, wenn X dran is
      document.querySelector("body").style["animation"] = "bg-scrolling-right 2s infinite";
    } else if (this.state.currentPlayer === "O") {
      // scrollt das Wallpaper nach Rechts, wenn O dran is
      document.querySelector("body").style["animation"] = "bg-scrolling-left 2s infinite";
    }
  }

  resetBoard = () => {
    this.setState({
      endCardVisible: false,
      currentPlayer: "X",
      shouldResetBoard: true
    })
  }

  boardWasReset = () => {
    this.setState({
      shouldResetBoard: false
    })
  }

  render() {
    return (
      <div className="two-player-game">
        <Header />
        <div className="row container main">
          <div className="col s12 push-m3 m6 " id="BoardContainer">
            <MegaTTTBoard onWin={(winner) => this.onWin(winner)} changePlayers={this.changePlayers} currentPlayer={this.state.currentPlayer} shouldResetBoard={this.state.shouldResetBoard} boardWasReset={this.boardWasReset} />
          </div>
          <div className="col s6 pull-m6 m3">
            <PlayerMenu player="X" wins={this.state.winsPlayerX} isActive={this.state.currentPlayer === "X"} name="Bob" />
          </div>
          <div className="col s6 m3">
            <PlayerMenu player="O" wins={this.state.winsPlayerO} isActive={this.state.currentPlayer === "O"} name="Alice" />
          </div>
        </div>
        <EndCard isVisible={this.state.endCardVisible} onClick={this.resetBoard} winner={this.state.currentPlayer === 'X' ? 'O': 'X'}/>
      </div >
    )
  }
}

export default TwoPlayerGame;