// returns:
// 	- "X", "O" if one of the players has won
// 	- "-" if there is a draw
// 	- "" if the game isn't decided yet
const calculateWinner = (board) => {
	for (let i = 0; i < 3; i++) {
		// check rows
		if (board[3 * i] !== "" && board[3 * i] === board[3 * i + 1] && board[3 * i + 1] === board[3 * i + 2])
			return board[3 * i];
		// check columns
		if (board[i] !== "" && board[i] === board[i + 3] && board[i + 3] === board[i + 6])
			return board[i];
	}
	// check diagonals
	if (board[0] !== "" && board[0] === board[4] && board[4] === board[8])
		return board[0];
	else if (board[2] !== "" && board[2] === board[4] && board[4] === board[6])
		return board[2];
	if (isBoardFull(board)) return "-";
	return "";
};

const isBoardFull = (board) => {
	let isBoardFull = true;
	for (let i = 0; i < 9; i++) {
		isBoardFull = isBoardFull && board[i] !== "";
	}
	return isBoardFull;
}

export { calculateWinner, isBoardFull };