import React from 'react'
import './PlayerMenu.css'

function PlayerMenu(props) {
	return (
		<div className={`player-menu ${props.isActive ? "player-menu-active": "player-menu-inactive"}`}>
			<h1><b>{props.name} - {props.player}</b></h1>
			<ul>
				<li>Wins: {props.wins}</li>
			</ul>
		</div>
	)
	
}


export default PlayerMenu;