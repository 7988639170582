import React, { Component } from 'react';
import TTTBoard from './TTTBoard';
import { calculateWinner, isBoardFull } from './gameLogic';
import './MegaTTTBoard.css';

class MegaTTTBoard extends Component {
	state = {
		sideLength: 100,
		boardStates: MegaTTTBoard.getResetBoard(),
		subWinners: new Array(9).fill(""),
		activeField: undefined,
		mainWinner: ""
	}
	
	componentDidMount = () => {
		window.addEventListener("resize", this.resizeBoard)
		this.resizeBoard();
	}

	componentDidUpdate = () => {
		// command to reset the board was sent from app-component
		if (this.props.shouldResetBoard) {
			this.reset();
			this.props.boardWasReset();
		}
	}
	
	reset = () => {
		this.setState({
			boardStates: MegaTTTBoard.getResetBoard(),
			subWinners: new Array(9).fill(""),
			activeField: undefined,
			mainWinner: ""
		})
	}

	resizeBoard = () => {
		const parentDiv = document.querySelector('#BoardContainer')
		this.setState({
			sideLength: parentDiv.clientWidth - 22.5
		})
	}

	static getResetBoard = () => {
		let mainBoard = [];
		for (let i = 0; i < 9; i++) {
			mainBoard.push(new Array(9).fill(""));
		}
		return mainBoard;
	}

	setMark = (posOnBoard, posOnSubboard) => {
		// check if right board is active or no board is active -> player has free choice
		if (this.state.activeField !== undefined && this.state.activeField !== posOnBoard) return;
		if (this.isSettingMarkSuccessful(posOnBoard, posOnSubboard)) {
			this.props.changePlayers();
			if (this.state.activeField !== null) this.setState({ activeField: posOnSubboard });
			const isNextActiveBoardFull = isBoardFull(this.state.boardStates[posOnSubboard]);
			if (isNextActiveBoardFull || this.state.mainWinner !== "") this.setState({ activeField: undefined });
		}
	}

	isSettingMarkSuccessful = (posOnBoard, posOnSubboard) => {
		// there is already a winner on Mainboard
		if (this.state.mainWinner !== "") return false;
		// there is already a winner on the Subboard
		// if (this.state.subWinners[posOnBoard] !== "") return false;
		// position is full already
		if (this.state.boardStates[posOnBoard][posOnSubboard] !== "") return false;
		// set the mark <finally> ...
		let copiedBoard = this.state.boardStates;
		copiedBoard[posOnBoard][posOnSubboard] = this.props.currentPlayer;
		let newSubwinners = this.state.subWinners;
		const isBoardAlreadyWon = newSubwinners[posOnBoard] !== "";
		if (!isBoardAlreadyWon) newSubwinners[posOnBoard] = calculateWinner(copiedBoard[posOnBoard]);
		const newMainWinner = calculateWinner(newSubwinners);
		if (newMainWinner === "X" || newMainWinner === "O") {
			this.props.onWin(newMainWinner);
			// activeField is (intentionally) null -> no mark can be set by anyone; game over
			this.setState({ activeField: null });
		}
		this.setState(
			{
				boardStates: copiedBoard,
				subWinners: newSubwinners,
				mainWinner: newMainWinner
			}
		)
		return true;
	}


	getRenderedSubboards = () => {
		const subboards = [];
		let onClickFunction;
		let newBoard;
		for (let i = 0; i < 9; i++) {
			onClickFunction = (posOnSubboard) => this.setMark(i, posOnSubboard);
			newBoard = <TTTBoard
				boardState={this.state.boardStates[i]}
				onClick={onClickFunction}
				winner={this.state.subWinners[i]}
				isActive={this.state.activeField === i || this.state.activeField === undefined}
				key={`subboard${i}`} />
			subboards.push(newBoard)
		}
		return subboards;
	}

	render() {
		const currentStyle = {
			width: this.state.sideLength,
			height: this.state.sideLength
		}
		return (
			<div className={`mega-ttt-board ${this.props.currentPlayer}-active`} style={currentStyle}>
				{this.getRenderedSubboards()}
			</div >
		)
	}
}

export default MegaTTTBoard;